export const $NotFound = {
  notFoundTitle: "Page Not Found",
  notFoundComment:
    "The requested URL was not found on this server. Please enter another URL.",
  notFoundButton: "Take Me Home",
};

export const $ErrorBottomDialog = {
  errorBottomDialogTitle: "We apologize for the inconvenience.",
  errorBottomDialogComment:
    "Due to a temporary server error, we ask that you come back later.",
  errorBottomDialogButton: "Refresh",
};

export const $SignInErrorDialog = {
  signInErrorDialogTitle: "",
  signInErrorDialogComment: "",
  signInErrorDialogButton: "",
};

export const $TrainingGameTitle = {
  "000": "Personalized Training",
  101: "",
  102: "Find the missing numbers",
  103: "Unlock the password",
  104: "",
  201: "Crossword puzzle",
  202: "",
  203: "",
  301: "",
  302: "Flags of all nations",
  303: "",
  304: "Where are you, fruit?",
  401: "",
  402: "Word scramble",
  501: "",
  502: "Sequence order",
  503: "",
  601: "Mental Rotation",
  602: "",
};

export const $TrainingCategories = {
  Memory: "Memory",
  ExecutiveFunction: "Executive",
  Calculation: "Calculation",
  Language: "Language",
  Attention: "Attention",
  VisuoSpatial: "VisuoSpatial",
};
