import { boot } from "quasar/wrappers";
import mitt from "mitt";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default boot(({ app, router }) => {
  const emit = mitt();
  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      // emit.all.clear();
      emit.off("openAssessmentGuide");
      emit.off("setTrainingListHeight");
      emit.off("sendMessage");
      emit.off("userInputOnClick");
      emit.off("userBirthOnClick");
      emit.off("userGenderOnClick");
      emit.off("userEducationOnClick");
      emit.off("setTrainingTimer");
      emit.off("countUpTrainingQuestionCount");
      emit.off("sendPass");
      emit.off("setQuestionEndTime");
      emit.off("getTrainingResult");
      emit.off("openCenterDialog");
      emit.off("handleCrosswordPuzzle");
      emit.off("handleSequenceOrder");
      emit.off("handlePassAble");
      emit.off("handleInputPassAble");
      emit.off("micAccessInteraction");
      emit.off("correctEvt");
      emit.off("wrongEvt");
      emit.off("needClickLastWord");

      // training
      emit.off("startCountdown");
      emit.off("startTraining");
      emit.off("setNextMainQuestion");
      emit.off("handleWrongAnswer");
      emit.off("openTrainingFeedback");
      emit.off("openTrainingDialog");
      emit.off("setIsSaveResult");
      emit.off("fetchTimeoutAnswer");
      next();
    }
  );

  app.use({
    install: (Vue) => {
      Vue.provide("mitt", emit);
    },
  });
});
