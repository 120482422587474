import { inject } from "vue";
import { Emitter, Handler } from "mitt";
import {
  TrainingDialogType,
  TrainingFeedbackType,
} from "src/types/training/trainingDialogType";

// training
type TrainingEventType = {
  startCountdown: undefined; // 카운트다운 시작
  startTraining: undefined; // (카운트다운 후) 훈련 시작
  setNextMainQuestion: undefined; // 다음 문제로
  handleWrongAnswer: undefined; // 오답 시
  fetchTimeoutAnswer: undefined; // 시간 초과 시 서버에 정답 전달 (t203)
  openTrainingFeedback: TrainingFeedbackType; // 정답 처리 팝업 열기, `type: TrainingFeedbackType` 전달
  openTrainingDialog: TrainingDialogType; // 훈련 중 발생하는 팝업 열기, `type: TrainingDialogType` 전달
  setIsSaveResult: boolean; // 훈련 결과 저장 성공 여부
};

type Events = TrainingEventType & {
  wrongEvt: undefined;
  correctEvt: string;
  openAssessmentGuide: undefined;
  setTrainingListHeight: undefined;
  sendMessage: string;
  userInputOnClick: undefined;
  userBirthOnClick: undefined;
  userGenderOnClick: undefined;
  userEducationOnClick: undefined;
  playAudio: string;
  setTrainingTimer: undefined;
  countUpTrainingQuestionCount: undefined;
  openExitDialog: undefined;
  openRetryDialog: string;
  sendPass: string;
  setQuestionEndTime: undefined;
  getTrainingResult: boolean;
  openCenterDialog: string;
  handleCrosswordPuzzle: string;
  handleSequenceOrder: string;
  handlePassAble: boolean;
  handleInputPassAble: boolean;
  micAccessInteraction: undefined;
  needClickLastWord: undefined;
};
type Key = keyof Events;
export type TEvents = Events[keyof Events];

export default function useMitt() {
  const mitt = inject("mitt") as Emitter<Events>;

  const on = (key: keyof Events, handler: Handler<Events[Key]>) =>
    mitt.on(key, handler);
  const off = (key: keyof Events, handler: Handler<Events[Key]>) =>
    mitt.off(key, handler);

  const clear = () => mitt.all.clear();

  const emit = (key: keyof Events, event: Events[Key]) => mitt.emit(key, event);

  return { on, off, emit, clear };
}
