import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUtilStore = defineStore("util", () => {
  const isLoading = ref(false);
  const getLoading = computed(() => isLoading.value);

  function setLoading(loadingValue: boolean) {
    isLoading.value = loadingValue;
  }

  const isAnalyzing = ref(false);
  const getAnalyzing = computed(() => isAnalyzing.value);

  function setAnalyzing(analyzingValue: boolean) {
    isAnalyzing.value = analyzingValue;
  }

  const isErrorBottom = ref(false);
  const getErrorBottom = computed(() => isErrorBottom.value);

  function setErrorBottom(errorValue: boolean) {
    isErrorBottom.value = errorValue;
  }

  const isErrorCenter = ref(false);
  const getErrorCenter = computed(() => isErrorCenter.value);

  function setErrorCenter(errorValue: boolean) {
    isErrorCenter.value = errorValue;
  }

  const isLicenseExpired = ref(false);
  const getIsLicenseExpired = computed(() => isLicenseExpired.value);

  function setIsLicenseExpired(expiredValue: boolean) {
    isLicenseExpired.value = expiredValue;
  }

  const needUpdate = ref(false);
  const getNeedUpdate = computed(() => needUpdate.value);
  function setNeedUpdate(updateFlag: boolean) {
    needUpdate.value = updateFlag;
  }

  return {
    getLoading,
    setLoading,
    getAnalyzing,
    setAnalyzing,
    getErrorBottom,
    setErrorBottom,
    getErrorCenter,
    setErrorCenter,
    getIsLicenseExpired,
    setIsLicenseExpired,
    getNeedUpdate,
    setNeedUpdate,
  };
});
