import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { UserResponse } from "src/generated/alzguardAPI";
import { computed, Ref } from "vue";

type TUser = UserResponse;

export const useUserStore = defineStore("user", () => {
  const user: Ref<TUser> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/user`,
    {} as TUser
  );

  const getUser = computed(() => {
    return user.value;
  });

  function setUser(newUser: Partial<TUser>) {
    user.value = { ...user.value, ...newUser };
  }

  function resetUser() {
    user.value = {} as TUser;
  }

  return {
    user,
    getUser,
    setUser,
    resetUser,
  };
});
