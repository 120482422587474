import { ComponentInternalInstance, getCurrentInstance } from "vue";

export default function useUtil() {
  const getComponentName = (): string | undefined => {
    const internalInstance: ComponentInternalInstance | null =
      getCurrentInstance();
    if (!internalInstance) return;
    return internalInstance.type.__name;
  };

  const delay = (num?: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), num));
  const createTimedPromise = (
    time: number
  ): { timerPromise: Promise<NodeJS.Timeout>; timeoutId: NodeJS.Timeout } => {
    let timeoutId!: NodeJS.Timeout;
    const timerPromise = new Promise<NodeJS.Timeout>((resolve) => {
      timeoutId = setTimeout(() => {
        resolve(timeoutId);
      }, time);
    });
    return { timerPromise, timeoutId };
  };

  /**
   * 배열의 요소를 무작위로 섞는 함수
   * Fisher-Yates 알고리즘을 사용하여 배열을 섞음
   * @param array 섞을 배열
   */
  function shuffleArray(array: Array<any>) {
    // 배열의 마지막 인덱스부터 시작
    let currentIndex = array.length;

    // 배열의 모든 요소를 순회하면서
    while (currentIndex != 0) {
      // 현재 인덱스보다 작은 무작위 인덱스 선택
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // 현재 요소와 무작위로 선택된 요소의 위치를 교환
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
  }

  return { getComponentName, delay, createTimedPromise, shuffleArray };
}
