export const CUSTOMER_SERVICE_NUMBER = "1544-5287";

export const LATEST_VERSION = {
  aos: "1.1.0",
  ios: "1.1.0",
};

export const STORE_URL = {
  aos: "https://play.google.com/store/apps/details?id=io.haii.alzant",
  ios: "https://apps.apple.com/kr/app/%EC%83%88%EB%AF%B8%EB%9E%91/id6478643152",
};
