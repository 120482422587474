import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { TAppType, tokenType } from "src/types/constType";
import { computed, ref, Ref } from "vue";
import { useUserStore } from "stores/useUserStore";

type TGeneral = {
  isApp: boolean;
  isMobile: boolean;
  isExtraDisplay: boolean;
  appType: TAppType;
  token: tokenType;
  isMainError: boolean;
  isOrganizationLogin: boolean;
  organizationUuid: string;
};

export type TOrganizationInfo = {
  name: string;
  uuid: string;
};

export const useGeneralStore = defineStore("general", () => {
  const general: Ref<TGeneral> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/general`,
    { isApp: false, isMobile: false, isExtraDisplay: false } as TGeneral
  );
  const getIsApp = computed(() => general.value.isApp);

  function setIsApp(appFlag: boolean) {
    general.value.isApp = appFlag;
  }

  const getIsMobile = computed(() => general.value.isMobile);
  function setIsMobile(mobileFlag: boolean) {
    general.value.isMobile = mobileFlag;
  }

  const getIsExtraDisplay = computed(() => general.value.isExtraDisplay);
  function setIsExtraDisplay(extraFlag: boolean) {
    general.value.isExtraDisplay = extraFlag;
  }

  const getAppType = computed(() => general.value.appType);

  function setAppType(appType: TAppType) {
    general.value.appType = appType;
  }

  const getToken = computed(() => general.value.token);

  function setToken(newToken: tokenType) {
    general.value.token = { ...newToken };
  }

  const getIsMainError = computed(() => general.value.isMainError);

  function setIsMainError(mainErrorFlag: boolean) {
    general.value.isMainError = mainErrorFlag;
  }

  const userStore = useUserStore();
  const getIsOrganizationLogin = computed(
    () => userStore.getUser.companyLoginFlag ?? false
  );

  const getOrganizationUuid = computed(() => general.value.organizationUuid);

  function setOrganizationUuid(organizationUuid: string) {
    general.value.organizationUuid = organizationUuid;
  }

  // 기관 로그인 관련
  const organizationEmail = ref("");
  const getOrganizationEmail = computed(() => organizationEmail.value);
  function setOrganizationEmail(newEmail: string) {
    organizationEmail.value = newEmail;
  }

  const organization: Ref<TOrganizationInfo> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/organization`,
    {} as TOrganizationInfo
  );
  const getOrganizationInfo = computed(() => organization.value);
  function setOrganizationInfo(newInfo: TOrganizationInfo) {
    organization.value = { ...newInfo };
  }

  return {
    getIsApp,
    setIsApp,
    getIsMobile,
    setIsMobile,
    getIsExtraDisplay,
    setIsExtraDisplay,
    getAppType,
    setAppType,
    getToken,
    setToken,
    getIsMainError,
    setIsMainError,
    getIsOrganizationLogin,
    getOrganizationUuid,
    setOrganizationUuid,
    getOrganizationEmail,
    setOrganizationEmail,
    getOrganizationInfo,
    setOrganizationInfo,
  };
});
